<template>

  <!-- profile info  -->
  <section id="ubah-pengajuan-master-data-informasi">
    <b-card
      header="Ubah Master Program Data dan Informasi"
      header-tag="h4"
      header-class="border-bottom mb-2"
    >
      <template v-if="alert">
        <b-alert
          :variant="alertDetail.variant"
          show
        >
          <h4 class="alert-heading">
            {{ alertDetail.title }}
          </h4>
          <div
            v-if="alertDetail.variant === 'danger' && alertDetail.errors !== ''"
            class="alert-body"
          >
            <ul v-if="typeof alertDetail.errors === 'object'">
              <li
                v-for="(item, index) in alertDetail.errors"
                :key="index"
              >
                {{ index }}: {{ item }}
              </li>
            </ul>
            <span v-else>{{ alertDetail.text + ': ' + alertDetail.errors }}</span>
          </div>
          <div
            v-else
            class="alert-body"
          >
            {{ alertDetail.text }}
          </div>
        </b-alert>
      </template>

      <validation-observer ref="formBisnis">
        <form
          id="editMasterBisnis"
          @submit.prevent="submitForm"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
                label="Data Pokok"
                label-for="vi-data_basic_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data Pokok"
                  rules="required"
                >
                  <v-select
                    id="vi-data_basic_id"
                    v-model="data_basic_id"
                    required
                    name="data_basic_id"
                    :options="referenceData.ref_data_pokok"
                    placeholder="Data Pokok"
                    label="nama"
                    @input="getDataTematik"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
                label="Data Tematik"
                label-for="vi-data_tematic_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data Tematik"
                  rules="required"
                >
                  <v-select
                    id="vi-data_tematic_id"
                    v-model="data_tematic_id"
                    required
                    name="data_tematic_id"
                    :options="daftarDataTematik"
                    placeholder="Data Tematik"
                    label="nama"
                    @input="getDataTopik"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'"
                label="Data Topik"
                label-for="vi-data_topic_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data Topik"
                  rules="required"
                >
                  <v-select
                    v-if="!fungsi_and_sub"
                    id="vi-data_topic_id"
                    v-model="data_topic_id"
                    required
                    name="data_topic_id"
                    :options="daftarDataTopik"
                    placeholder="Data Topik"
                    label="nama"
                    @input="getDataSubTopik"
                  />
                  <b-input-group
                    v-if="fungsi_and_sub"
                  >
                    <b-form-input
                      v-if="fungsi_and_sub"
                      id="vi-data_topic_id"
                      v-model="data_topic_nama"
                      required
                      name="data_topic_id"
                      placeholder="Data Topik"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-secondary"
                        @click="unitReset('cat')"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          size="12"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'"
                label="Data Sub Topik"
                label-for="vi-sub_data_topic_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data Sub Topik"
                  rules="required"
                >
                  <v-select
                    v-if="!sub_fungsi"
                    id="vi-sub_data_topic_id"
                    v-model="sub_data_topic_id"
                    required
                    name="sub_data_topic_id"
                    :options="daftarDataSubTopik"
                    placeholder="Data Sub Topik"
                    label="nama"
                    @input="setSubFungsiGov"
                  />
                  <b-input-group
                    v-if="sub_fungsi"
                  >
                    <b-form-input
                      v-if="sub_fungsi"
                      id="vi-sub_data_topic_id"
                      v-model="sub_data_topic_nama"
                      required
                      name="sub_data_topic_id"
                      placeholder="Data Sub Topik"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-secondary"
                        @click="unitReset('sub')"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          size="12"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan data yang dikelola oleh unit kerja/perangkat daerah.'"
                label="Nama Data"
                label-for="vi-nama"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Data"
                  rules="required"
                >
                  <b-form-input
                    id="vi-nama"
                    v-model="dataData.nama"
                    required
                    name="nama"
                    placeholder="Nama Data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'"
                label="Interoperabilitas Data"
                label-for="vi-data_interoperability_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Interoperabilitas Data"
                  rules="required"
                >
                  <v-select
                    id="vi-data_interoperability_id"
                    v-model="data_interoperability_id"
                    required
                    name="data_interoperability_id"
                    :options="daftarDataInteroperability"
                    placeholder="Interoperabilitas Data"
                    label="nama"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
                label="Sifat Data"
                label-for="vi-data_status_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Sifat Data"
                  rules="required"
                >
                  <v-select
                    id="vi-data_status_id"
                    v-model="data_status_id"
                    required
                    name="data_status_id"
                    :options="referenceData.ref_sifat_data"
                    placeholder="Sifat Data"
                    label="nama"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan penjelasan tujuan dari pemanfaatan data.'"
                label="Tujuan Data"
                label-for="vi-tujuan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tujuan Data"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-tujuan"
                    v-model="dataData.tujuan"
                    rows="4"
                    required
                    name="tujuan"
                    placeholder="Tujuan Data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan tanggal mulai berlakunya data.'"
                label="Awal Validitas Data"
                label-for="vi-valid_start"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Awal Validitas Data"
                  rules="required"
                >
                  <b-form-datepicker
                    id="vi-valid_start"
                    v-model="dataData.valid_start"
                    :max="valid_end"
                    :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                    required
                    name="valid_start"
                    placeholder="Awal Validitas Data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan tanggal akhir berlakunya data.'"
                label="Akhir Validitas Data"
                label-for="vi-valid_end"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Akhir Validitas Data"
                  rules="required"
                >
                  <b-form-datepicker
                    id="vi-valid_end"
                    v-model="dataData.valid_end"
                    :min="valid_start"
                    :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                    required
                    name="valid_end"
                    placeholder="Akhir Validitas Data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan penanggung jawab dari data.'"
                label="Penanggung Jawab"
                label-for="vi-pic_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Penanggung Jawab"
                  rules="required"
                >
                  <b-form-input
                    id="vi-pic_name"
                    v-model="dataData.pic_name"
                    required
                    name="pic_name"
                    placeholder="Penanggung Jawab"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'"
                label="Uraian Data"
                label-for="vi-deskripsi"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Uraian Data"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-deskripsi"
                    v-model="dataData.deskripsi"
                    rows="4"
                    required
                    name="deskripsi"
                    placeholder="Uraian Data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan wali dari data.'"
                label="Wali Data"
                label-for="vi-wali_data"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Wali Data"
                  rules="required"
                >
                  <b-form-input
                    id="vi-wali_data"
                    v-model="dataData.walidata"
                    required
                    name="wali_data"
                    placeholder="Wali Data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              class="mb-1"
            >
              <b-button
                type="submit"
                variant="primary"
              >
                Simpan
              </b-button>
              <b-button
                :to="{ path: '/pengajuan/master/data-informasi' }"
                variant="outline-secondary"
                class="ml-50"
              >
                Kembali
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-card>
  </section>
  <!--/ profile info  -->

</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BAlert, BButton, BRow, BCol, BCard, BFormGroup, BFormTextarea, VBTooltip, BFormInput,
  BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BButton,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      userData: getUserData(),
      referenceData: {
        data_informasi_list: [],
        ref_data_pokok: [],
        ref_data_tematik: [],
        ref_sifat_data: [],
        ref_data_topic: [],
        ref_sub_data_topic: [],
      },
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      dataData: {},
      data_basic_id: '',
      data_tematic_id: '',
      data_topic_id: '',
      sub_data_topic_id: '',
      nama: '',
      data_interoperability_id: '',
      data_status_id: '',
      tujuan: '',
      valid_start: '',
      valid_end: '',
      pic_name: '',
      deskripsi: '',
      daftarDataInteroperability: [
        { value: '0', nama: 'Tidak' },
        { value: '1', nama: 'Ya' },
      ],
      daftarDataTematik: [],
      daftarDataTopik: [],
      daftarDataSubTopik: [],
      daftarMasterTopik: { data_topic_id: 'new', nama: 'Tambah Fungsi Pemerintahan Baru' },
      daftarMasterSubTopik: { sub_data_topic_id: 'new', nama: 'Tambah Sub Fungsi Pemerintahan Baru' },
      fungsi_and_sub: false,
      sub_fungsi: false,
      data_topic_nama: '',
      sub_data_topic_nama: '',
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 110 || role < 99) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  created() {
    this.master_danis_id = Number(this.$route.params.id)
  },
  mounted() {
    this.getReference()
  },
  methods: {
    getReference() {
      this.$http.get('/clearance/data-informasi/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            this.setReference()
          }
        })
    },
    setReference() {
      this.$http.get('master/master-danis/get', {
        params: {
          token: localStorage.getItem('userToken'),
          master_danis_id: this.master_danis_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataData = res.data.data

            this.data_basic_id = {
              data_pokok_id: this.dataData.data_pokok_id,
              nama: this.dataData.data_pokok,
            }
            this.getDataTematik()

            if (this.dataData.data_tematik_id) {
              this.data_tematic_id = {
                data_tematik_id: this.dataData.data_tematik_id,
                nama: this.dataData.data_tematik,
              }
              this.getDataTopik()
            }

            if (this.dataData.data_topic_id) {
              this.data_topic_id = {
                data_topic_id: this.dataData.data_topic_id,
                nama: this.dataData.data_topic,
              }
              this.getDataSubTopik()
            }

            if (this.dataData.sub_data_topic_id) {
              this.sub_data_topic_id = {
                sub_data_topic_id: this.dataData.sub_data_topic_id,
                nama: this.dataData.sub_data_topic,
              }
            }

            this.data_status_id = {
              sifat_data_id: this.dataData.sifat_data_id,
              nama: this.dataData.sifat_data,
            }
            this.data_interoperability_id = this.daftarDataInteroperability[this.dataData.interoperability_id]
          }
        })
    },
    getDataTematik() {
      this.daftarDataTematik.splice(0)
      this.data_tematic_id = ''

      const filterVal = this.data_basic_id.data_pokok_id
      const refData = this.referenceData.ref_data_tematik
      refData.map(contact => {
        if (contact.data_pokok_id === filterVal) {
          this.daftarDataTematik.push(contact)
        }
        return true
      })
    },
    getDataTopik() {
      this.daftarDataTopik = [this.daftarMasterTopik]
      this.data_topic_id = ''

      const filterVal = this.data_tematic_id.data_tematik_id
      const refData = this.referenceData.ref_data_topic
      refData.map(contact => {
        if (contact.data_tematik_id === filterVal) {
          this.daftarDataTopik.push(contact)
        }
        return true
      })

      if (this.daftarDataTopik.length === 1) {
        this.fungsi_and_sub = true
        this.sub_fungsi = true
      }
    },
    getDataSubTopik() {
      this.daftarDataSubTopik = [this.daftarMasterSubTopik]
      this.sub_data_topic_id = ''

      const filterVal = this.data_topic_id.data_topic_id
      const refData = this.referenceData.ref_sub_data_topic
      refData.map(contact => {
        if (contact.data_topic_id === filterVal) {
          this.daftarDataSubTopik.push(contact)
        }
        return true
      })

      if (this.data_topic_id && this.data_topic_id.data_topic_id === 'new') {
        this.fungsi_and_sub = true
      }

      if (this.daftarDataSubTopik.length === 1) {
        this.sub_fungsi = true
      }
    },
    setSubFungsiGov() {
      if (this.sub_data_topic_id && this.sub_data_topic_id.sub_data_topic_id === 'new') {
        this.sub_fungsi = true
      }
    },
    unitReset(val) {
      if (val === 'cat') {
        this.fungsi_and_sub = false
        this.sub_fungsi = false
        this.data_topic_nama = ''
        this.sub_data_topic_nama = ''
      }
      if (val === 'sub') {
        this.sub_fungsi = false
        this.sub_data_topic_nama = ''
      }
    },
    submitForm() {
      this.$refs.formBisnis.validate().then(success => {
        if (success) {
          this.simpanTemplate()
        }
      })
    },
    simpanTemplate() {
      const metaUtama = {
        master_danis_id: this.dataData.master_danis_id,
        instansi_id: this.dataData.instansi_id,
        data_pokok_id: this.data_basic_id ? this.data_basic_id.data_pokok_id : 1,
        data_tematik_id: this.data_tematic_id ? this.data_tematic_id.data_tematik_id : 1,
        nama: this.dataData.nama,
        interoperability_id: Number(this.data_interoperability_id.value),
        sifat_data_id: this.data_status_id.sifat_data_id,
        tujuan: this.dataData.tujuan,
        valid_start: this.dataData.valid_start,
        valid_end: this.dataData.valid_end,
        pic_name: this.dataData.pic_name,
        deskripsi: this.dataData.deskripsi,
        walidata: this.dataData.walidata,
      }
      if (this.data_topic_id && this.data_topic_id.data_topic_id !== 'new') {
        metaUtama.data_topic_id = this.data_topic_id.data_topic_id
      }
      if (this.sub_data_topic_id && this.sub_data_topic_id.sub_data_topic_id !== 'new') {
        metaUtama.sub_data_topic_id = this.sub_data_topic_id.sub_data_topic_id
      }
      if (this.data_topic_nama) {
        metaUtama.data_topic_nama = this.data_topic_nama
      }
      if (this.sub_data_topic_nama) {
        metaUtama.sub_data_topic_nama = this.sub_data_topic_nama
      }

      this.$http.post('/master/master-danis/add', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.alert = true
            this.alertDetail = {
              variant: 'success',
              title: 'Data Saved',
              text: res.data.message,
            }

            setTimeout(() => {
              this.alert = false
            }, 2000)
          } else {
            this.alert = true
            this.alertDetail = {
              variant: 'danger',
              title: 'Error Found',
              text: `${res.data.message}: ${res.data.error}`,
            }
          }
        })
        .catch(error => {
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.message !== '') ? error.response.data.message : error,
            errors: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
